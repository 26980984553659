import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useState } from 'react'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

import { Banner } from './Banner'
import './style.scss'
import type { HeroBanner } from './types'

interface Props {
  banners: HeroBanner[]
  section: string
  locationOnPage: string
}

export const HeroSlider = ({ banners, section, locationOnPage }: Props) => {
  const [slideActive, setSlideActive] = useState(0)

  return (
    <Splide
      options={{
        pagination: true,
        type: 'loop',
        autoplay: true,
        interval: 4000,
        easing: 'cubic-bezier(0.42, 0, 0.58, 1)',
        pauseOnFocus: false,
      }}
      hasTrack={false}
      className="relative"
      onVisible={(_, slide) => {
        setSlideActive(slide.index)
      }}
    >
      <SplideTrack>
        {banners.map((bannerProps, index) => {
          return (
            <SplideSlide
              key={index}
              data-index={`element-splid-item${index}`}
              aria-label={bannerProps.title?.text}
              className="h-max"
            >
              <Banner
                {...bannerProps}
                index={index}
                inSlide
                slideActive={slideActive === index}
                section={section}
                locationOnPage={locationOnPage}
              />
            </SplideSlide>
          )
        })}
      </SplideTrack>
      <div className="absolute z-20 bottom-2 restructure-tablet:bottom-4 left-0 w-full restructure-medium-desktop:left-auto restructure-medium-desktop:right-20 restructure-medium-desktop:max-w-[224px] restructure-medium-desktop:h-[48px] restructure-medium-desktop:bottom-12">
        <div className="custom-controls relative h-2 restructure-medium-desktop:h-12">
          <div className="splide__arrows hidden restructure-medium-desktop:flex h-12">
            <button
              className="splide__arrow splide__arrow--prev"
              type="button"
              aria-label="Previous slide"
              aria-controls="splide01-track"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303C11.1768 19.2374 11.1768 18.7626 11.4697 18.4697L17.9393 12L11.4697 5.53033C11.1768 5.23744 11.1768 4.76256 11.4697 4.46967Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              className="splide__arrow splide__arrow--next"
              type="button"
              aria-label="Next slide"
              aria-controls="splide01-track"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303C11.1768 19.2374 11.1768 18.7626 11.4697 18.4697L17.9393 12L11.4697 5.53033C11.1768 5.23744 11.1768 4.76256 11.4697 4.46967Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <ul className="splide__pagination restructure-medium-desktop:!bottom-1/2 restructure-medium-desktop:translate-y-1/2" />
        </div>
      </div>
    </Splide>
  )
}
