import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'
import { Container } from 'src/components/department/Container'
import { useMobile } from 'src/hooks/useMobile'
import { InView } from 'react-intersection-observer'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'

import './pictos.scss'

type Item = {
  image: string
  textColor?: 'preto' | 'branco'
  text: string
  altImage: string
  linkUrl: string
}

type Props = {
  items: Item[]
  parentComponent?: string
  section?: string
  locationOnPage?: string
}

export function Pictos(props: Props) {
  const { screenWidth } = useMobile()
  const standardObject = getStandardObject()

  const { section, locationOnPage, items } = props

  const handleClickLink = (name: string, imageAlt: string, index: number) => {
    if (!locationOnPage || !section) {
      return
    }

    const featureObject = getFeatureObject({
      section,
      locationOnPage,
      name: imageAlt ?? name,
    })

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': name ?? '',
        'promotional element index': index ?? 0,
      },
    })

    if (!window) {
      return
    }

    window.dataLayer = window.dataLayer ?? []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'event_track',
      event_category: 'clique',
      event_action: name,
      event_label: 'Pagina de departamento',
    })
  }

  const sendPromoFeatureViewedEvent = (item: Item) => {
    if (!locationOnPage || !section) {
      return
    }

    const eventData = {
      ...getStandardObject(),
      ...getFeatureObject({
        section,
        locationOnPage,
        name:
          section === ' Pictos [Departamento]'
            ? item?.text ?? ''
            : item?.altImage ?? '',
      }),
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  return (
    <Container className="restructure-maxMobile:my-10  restructure-small-desktop:my-[104px] !px-0">
      <ul className="pictos flex items-start justify-between max-w-full overflow-x-scroll scrollbar-hide gap-4 sm:gap-10">
        {items?.map((item, index) => (
          <InView
            as="li"
            threshold={0.7}
            onChange={(inView) => {
              if (inView) {
                sendPromoFeatureViewedEvent(item)
              }
            }}
            triggerOnce
            key={item.text}
            draggable={false}
            data-testid="picto-card"
            className="picto-card"
          >
            <Link
              to={item.linkUrl}
              className="flex flex-col justify-center items-center w-[96px] restructure-small-desktop:w-[124px]"
              onClick={() => handleClickLink(item.text, item.altImage, index)}
              aria-label={`link para a categoria ${item.text}`}
            >
              <span className="w-[64px] h-[64px] restructure-small-desktop:w-[120px] restructure-small-desktop:h-[120px] flex items-center justify-center">
                <Image
                  loading="lazy"
                  src={item?.image}
                  height={screenWidth > 900 ? 120 : 64}
                  width={screenWidth > 900 ? 120 : 64}
                  alt={item?.altImage ?? ''}
                  title={item.text}
                  data-id={item.text}
                  data-banner-type="Categories Shelf"
                />
              </span>
              <h2
                data-testid="picto-text"
                className="text-center font-inter mt-3 text-sm sm:text-base text-restructure-primary"
              >
                {item.text}
              </h2>
            </Link>
          </InView>
        ))}
      </ul>
    </Container>
  )
}
